import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "./Utils";
import API                  from "./API";



// Variables
const initialState = {
    error         : false,
    loading       : true,

    showStartPage : true,
    showSettings  : false,
    showUnread    : true,
    platform      : null,
    language      : "",

    url           : "",
    widgetHash    : "",
    externalID    : "",
    externalUrl   : "",
    name          : "",
    email         : "",

    contactID     : 0,
    texts         : {},
    languages     : [],
    options       : {},
    platforms     : [],
    conversation  : {},
    hasMore       : false,
    hasUnread     : false,
    unreadCount   : 0,
    messages      : [],
    lastMessage   : {},
    items         : [],
    lastUpdate    : 0,
};
const rootReducer = (state, action) => {
    return { ...state, ...action };
};
const Context = React.createContext([]);



/**
 * Creates the Store Provider
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Provider({ children }) {
    const [ state, dispatch ] = React.useReducer(rootReducer, initialState);
    const store = React.useMemo(() => [ state, dispatch ], [ state ]);

    return <Context.Provider value={store}>
        {children}
    </Context.Provider>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Provider.propTypes = {
    children : PropTypes.any,
};



/**
 * Returns the Store Hook
 * @returns {Array}
 */
function useStore() {
    return React.useContext(Context);
}

/**
 * Returns the State Hook
 * @returns {Object}
 */
function useState() {
    const [ state ] = useStore();
    return state;
}

/**
 * Returns the Dispatch Hook
 * @returns {Function}
 */
function useDispatch() {
    const [ , dispatch ] = useStore();
    return dispatch;
}



/**
 * Returns the Language Hook
 * @returns {Object}
 */
function useLanguage() {
    const dispatch = useDispatch();

    return (language) => {
        Utils.storeItem("conversana-language", language);
        dispatch({ language });
    };
}

/**
 * Returns the Text Hook
 * @returns {Object}
 */
function useText() {
    const [ state ] = useStore();
    const { language, texts, options } = state;

    return (property, defaultValue = "") => {
        if (options[property] && Utils.isObject(options[property])) {
            return options[property][language] || defaultValue;
        }
        if (texts[property]) {
            return texts[property][language] || defaultValue;
        }
        return defaultValue;
    };
}



/**
 * Returns a function to Show the Start
 * @returns {Function}
 */
function useStart() {
    const dispatch = useDispatch();
    return (showStartPage) => {
        const options = { showStartPage };
        if (showStartPage) {
            options.showUnread = true;
        }
        dispatch(options);
    };
}

/**
 * Returns a function to Show the Settings
 * @returns {Function}
 */
function useSettings() {
    const dispatch = useDispatch();
    return (showSettings) => {
        dispatch({ showSettings });
    };
}

/**
 * Returns a function to Show the Platform
 * @returns {Function}
 */
function usePlatform() {
    const dispatch = useDispatch();
    return (platform) => {
        dispatch({ platform });
    };
}



/**
 * Returns a function to Initialize the Chat
 * @returns {Function}
 */
function useInitial() {
    const dispatch = useDispatch();

    return async () => {
        // Obtain the widget hash
        const widgetHash = window.location.pathname.substring(1);
        if (!widgetHash) {
            dispatch({
                error   : true,
                loading : true,
            });
            return;
        }

        // Obtain the external ID
        let externalID = Utils.createHash(20);
        const oldExternalID = Utils.restoreItem("conversana-externalID");
        if (oldExternalID) {
            externalID = oldExternalID;
        } else {
            Utils.storeItem("conversana-externalID", externalID);
        }

        // Obtain the initial data
        const result = await API.getInitial({ widgetHash, externalID });
        if (result.error) {
            dispatch({
                error   : true,
                loading : true,
            });
            return;
        }

        // Obtain the name and email
        const urlParams   = new URLSearchParams(window.location.search);
        const externalUrl = urlParams.get("url")   ?? "";
        const name        = urlParams.get("name")  ?? "";
        const email       = urlParams.get("email") ?? "";

        // Parse the messages
        const { items, unreadCount } = Utils.parseMessages(result.messages, true);
        dispatch({
            error         : false,
            loading       : false,

            showStartPage : !externalUrl.includes("conversana=1") || !result.conversation.id,
            language      : Utils.getLanguage(result.languages, result.defaultLanguage),
            widgetHash    : widgetHash,
            externalID    : externalID,
            externalUrl   : externalUrl,
            name          : name,
            email         : email,

            contactID     : result.contactID,
            url           : result.url,
            texts         : result.texts,
            languages     : result.languages,
            options       : result.options,
            platforms     : result.platforms,
            conversation  : result.conversation,
            hasMore       : result.hasMore,
            hasUnread     : unreadCount > 0,
            unreadCount   : unreadCount,
            messages      : result.messages,
            lastMessage   : result.messages.length > 0 ? result.messages[result.messages.length - 1] : {},
            items         : items,
            lastUpdate    : result.lastUpdate,
        });
    };
}

/**
 * Returns a function to get New Messages
 * @returns {Function}
 */
function useNewMessages() {
    const [ state, dispatch ] = useStore();
    const { widgetHash, externalID, contactID, conversation, messages, showUnread, lastUpdate } = state;

    return async () => {
        const conversationID = conversation.id;
        const result         = await API.getNewMessages({ widgetHash, externalID, contactID, conversationID, lastUpdate });
        if (!result.error) {
            dispatchMessages(dispatch, result, messages, showUnread);
        }
    };
}

/**
 * Returns a function to get Old Messages
 * @returns {Function}
 */
function useOldMessages() {
    const [ state, dispatch ] = useStore();
    const { widgetHash, contactID, conversation, messages, showUnread } = state;

    return async () => {
        const conversationID = conversation.id;
        const first          = messages[0];
        const time           = first.createdTime;
        const result         = await API.getOldMessages({ widgetHash, contactID, conversationID, time });
        if (result.error) {
            return {};
        }

        messages.unshift(...result.messages);
        const { items, unreadCount } = Utils.parseMessages(result.messages, showUnread);
        dispatch({
            items,
            conversation : result.conversation,
            hasUnread    : unreadCount > 0,
            unreadCount  : unreadCount,
            messages     : messages,
        });
        return {
            oldMessageID : first.messageID,
            newMessageID : messages[0].messageID,
        };
    };
}

/**
 * Returns a function to Hide the Unread Messages
 * @returns {Function}
 */
function useHideUnread() {
    const dispatch = useDispatch();
    return () => {
        dispatch({ showUnread : false });
    };
}

/**
 * Marks the Messages as Read
 * @returns {Function}
 */
function useMarkAsRead() {
    const [ state, dispatch ] = useStore();
    const { widgetHash, externalID, contactID, conversation, messages, showUnread } = state;

    return async () => {
        const conversationID = conversation.id;
        const result         = await API.markAsRead({ widgetHash, externalID, contactID, conversationID });
        if (!result.error) {
            dispatchMessages(dispatch, result, messages, showUnread);
        }
    };
}

/**
 * Dispatches the New Messages
 * @param {Function} dispatch
 * @param {Number}   conversationID
 * @param {String}   widgetHash
 * @param {String}   externalID
 * @param {Number}   contactID
 * @param {Number}   lastUpdate
 * @param {Object[]} messages
 * @param {Boolean}  showUnread
 * @returns {Number}
 */
function dispatchNewMessages(dispatch, conversationID, widgetHash, externalID, contactID, lastUpdate, messages, showUnread) {
    return window.setTimeout(async () => {
        const result = await API.getNewMessages({ widgetHash, externalID, contactID, conversationID, lastUpdate });
        if (!result.error) {
            dispatchMessages(dispatch, result, messages, showUnread);
        }
    }, 1000);
}

/**
 * Dispatches the Messages
 * @param {Function} dispatch
 * @param {Object}   result
 * @param {Object[]} oldMessages
 * @param {Boolean}  showUnread
 * @returns {Void}
 */
function dispatchMessages(dispatch, result, oldMessages, showUnread) {
    const newMessages = Utils.addMessages(oldMessages, result.messages);
    const { items, unreadCount } = Utils.parseMessages(newMessages, showUnread);

    dispatch({
        items,
        contactID    : result.contactID,
        conversation : result.conversation,
        hasUnread    : unreadCount > 0,
        unreadCount  : unreadCount,
        messages     : newMessages,
        lastMessage  : newMessages.length ? newMessages[newMessages.length - 1] : {},
        lastUpdate   : result.lastUpdate,
    });
}



/**
 * Returns a function to Send a Message
 * @returns {Function}
 */
function useMessage() {
    const [ state, dispatch ] = useStore();
    const { conversation, widgetHash, externalID, externalUrl, contactID, email, messages, showUnread, language, lastUpdate } = state;

    return async (name, message, file = "", replyToID = 0) => {
        if (!message && !file) {
            return;
        }
        const timeout = dispatchNewMessages(dispatch, conversation.id, widgetHash, externalID, contactID, lastUpdate, messages, showUnread);
        const uuid    = Utils.createHash(20);
        const result  = await API.sendMessage({
            widgetHash, externalID, externalUrl, uuid,
            name, email, message, file, language, lastUpdate, replyToID,
        });
        if (!result.error) {
            dispatchMessages(dispatch, result, messages, showUnread);
        }
        window.clearTimeout(timeout);
    };
}

/**
 * Returns a function to Send a Location
 * @returns {Function}
 */
function useLocation() {
    const [ state, dispatch ] = useStore();
    const { conversation, widgetHash, externalID, contactID, messages, showUnread, language, lastUpdate } = state;

    return async (latitude, longitude, message) => {
        if (!latitude || !longitude) {
            return;
        }
        const timeout = dispatchNewMessages(dispatch, conversation.id, widgetHash, externalID, contactID, lastUpdate, messages, showUnread);
        const uuid    = Utils.createHash(20);
        const result  = await API.sendMessage({
            widgetHash, externalID, uuid,
            message, latitude, longitude, language, lastUpdate,
        });
        if (!result.error) {
            dispatchMessages(dispatch, result, messages, showUnread);
        }
        window.clearTimeout(timeout);
    };
}

/**
 * Returns a function to Send an Option
 * @returns {Function}
 */
function useOption() {
    const [ state, dispatch ] = useStore();
    const { conversation, widgetHash, externalID, contactID, messages, showUnread, lastUpdate } = state;

    return async (payload, message, replyToID) => {
        if (!payload || !message) {
            return;
        }

        const timeout = dispatchNewMessages(dispatch, conversation.id, widgetHash, externalID, contactID, lastUpdate, messages, showUnread);
        const uuid    = Utils.createHash(20);
        const result  = await API.sendMessage({
            widgetHash, externalID, uuid,
            message, payload, lastUpdate, replyToID,
        });
        if (!result.error) {
            dispatchMessages(dispatch, result, messages, showUnread);
        }
        window.clearTimeout(timeout);
    };
}

/**
 * Returns a function to Send a Reaction
 * @returns {Function}
 */
function useReaction() {
    const [ state, dispatch ] = useStore();
    const { widgetHash, externalID, messages, showUnread, lastUpdate } = state;

    return async (messageID, reaction) => {
        if (!messageID && !reaction) {
            return;
        }
        const uuid   = Utils.createHash(20);
        const result = await API.sendReaction({
            widgetHash, externalID, uuid,
            messageID, reaction, lastUpdate,
        });
        if (!result.error) {
            dispatchMessages(dispatch, result, messages, showUnread);
        }
    };
}




// The public API
export default {
    Provider,
    useState,

    useLanguage,
    useText,

    useStart,
    useSettings,
    usePlatform,

    useInitial,
    useNewMessages,
    useOldMessages,
    useHideUnread,
    useMarkAsRead,

    useMessage,
    useLocation,
    useOption,
    useReaction,
};
