import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Title                from "Components/Utils/Title";
import Icon                 from "Components/Utils/Icon";
import Backdrop             from "Components/Utils/Backdrop";



// Styles
const Container = Styled.div`
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 30px;
    margin: 0 auto;
    max-width: 400px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background-color: white;
    border-radius: var(--border-radius);
`;

const CloseIcon = Styled(Icon)`
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 20px;
    cursor: pointer;
`;

const Language = Styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const Select = Styled.select`
    flex-grow: 2;
    margin: 0;
    padding: 8px 12px;
    border: var(--border);
    border-radius: var(--border-radius-small);
    background-color: white;
    font-size: 14px;
    color: #687992;

    &:focus, &:hover {
        border-color: black;
        outline: none;
    }
`;

const Button = Styled.button`
    border: none;
    border-radius: var(--border-radius-small);
    padding: 8px 12px;
    color: white;
    background-color: var(--widget-button-bg);
    font-size: 14px;
    cursor: pointer;
`;



/**
 * The Settings
 * @returns {React.ReactElement}
 */
function Settings() {
    const { showSettings, language, languages } = Store.useState();

    const gotoSettings = Store.useSettings();
    const setLanguage  = Store.useLanguage();
    const getText      = Store.useText();


    // The Current State
    const [ newLanguage, setNewLanguage ] = React.useState(language);


    // Handles the Click
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // Handles the Change
    const handleChange = (e) => {
        setNewLanguage(e.target.value);
    };

    // Handles the Save
    const handleSave = () => {
        if (language !== newLanguage) {
            setLanguage(newLanguage);
        }
        handleClose();
    };

    // Handles the Close
    const handleClose = () => {
        gotoSettings(false);
    };



    // Do the Render
    if (!showSettings) {
        return <React.Fragment />;
    }
    return <Backdrop onClick={handleClose}>
        <Container onClick={handleClick}>
            <CloseIcon icon="close" onClick={handleClose} />
            <Title>{getText("settingsTitle")}</Title>
            <Language>
                <label htmlFor="select">
                    {getText("settingsLanguage")}
                </label>
                <Select
                    id="select"
                    value={newLanguage}
                    onChange={handleChange}
                >
                    {languages.map(({ key, value }) => <option key={key} value={key}>
                        {value}
                    </option>)}
                </Select>
            </Language>
            <Button onClick={handleSave}>
                {getText("settingsButton")}
            </Button>
        </Container>
    </Backdrop>;
}

export default Settings;
